const axiosNotificationsAPI = 'https://dev.typixweb.com/notification';
const axiosOfflineComparisonAPI = 'https://offline-comparison.ty-pix.ovh:60001';
const axiosPixOpAPI = 'http://137.74.200.56:8686';
const axiosPrivateAPI = 'https://typix-dev-api-connector.azuremicroservices.io';
const axiosBillingAPI = 'https://dev.typixweb.com/billing';
const oldAxiosPrivateAPI = 'http://docker-dev.monetoring.com:9000/';
const axiosMonetoringAPI =
  'https://api.monetoring.com/topic_detection_upper_keywords_manager_for_typix/1.0';
const animationUrl =
  'https://animationcloud.blob.core.windows.net/animation-container';

export {
  axiosNotificationsAPI,
  axiosOfflineComparisonAPI,
  axiosPixOpAPI,
  axiosPrivateAPI,
  axiosBillingAPI,
  oldAxiosPrivateAPI,
  axiosMonetoringAPI,
  animationUrl,
};
